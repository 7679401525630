<div class="flex items-center pt-5 px-5">
    <span *ngIf="selection?.selected?.length">{{ selection?.selected?.length }} linhas selecionadas.</span>
    <div class="w-full">
        <filters [option]="filterOptions" (filterParams)="handleFilterParams($event)" [params]="params" />
    </div>
</div>

<div *ngIf="loading; else showTable" class="loading-table">
    <app-loading></app-loading>
</div>

<ng-template #showTable>
    <div class="generic-table">
        <table mat-table [dataSource]="dataSource">
            <!-- Select -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="handleCheckboxChange($event, row)"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- For das columns -->
            <ng-container *ngFor="let col of columns; let i = index" [matColumnDef]="col.id">
                <th mat-header-cell *matHeaderCellDef class="dark:text-white">
                    <div class="th-header">
                        <span>{{ col.name }}</span>
                        <div *ngIf="col.type === 'image'" class="w-full items-center justify-center">
                            <mat-icon class="text-lg sm:text-3xl w-full">image</mat-icon>
                        </div>
                        <!-- <div class="sort-icons" *ngIf="col.type !== 'image' && col.type !== 'cat' && col.id !== 'review'">
              <mat-icon (click)="sortData(col.id, 'asc'); setIndex(i)" [class.active]="selectedIndex === i">
                keyboard_arrow_up
              </mat-icon>
              <mat-icon (click)="sortData(col.id, 'desc'); setIndex(-i - 1)" [class.active]="selectedIndex === -i - 1">
                keyboard_arrow_down
              </mat-icon>
            </div> -->
                    </div>
                </th>

                <td mat-cell *matCellDef="let row"
                    class="relative dark:text-white"
                    [ngClass]="{'w-[110px] max-w-[110px]': col.type == 'pedido-uuid'}">
                    <ng-container [ngSwitch]="col.type">
                        <ng-container *ngSwitchCase="'string'">{{ col.cell(row) }}</ng-container>

                        <ng-container *ngSwitchCase="'image'">
                            <div class="flex items-center justify-center p-0 my-0 sm:my-2">
                                <div *ngIf="col.cell(row); else icon">
                                    <img [src]="col.cell(row)" alt="imagem" class="h-[90px]" loading="lazy" />
                                </div>
                                <ng-template #icon>
                                    <mat-icon class="h-[99px]">no_photography</mat-icon>
                                </ng-template>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'nameidref'">
                            <div class="flex flex-col">
                                <span class="text-base">{{ col.cell(row)[0] }}</span>
                                <span class="hidden sm:block text-sm">{{ col.cell(row)[1] }}</span>
                                <span class="hidden sm:block text-sm">{{ col.cell(row)[2] }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'time'">
                            <span *ngIf="use_uuid" class="whitespace-nowrap">{{ col.cell(row) | formatDate}}</span>
                        </ng-container>

                        <ng-container *ngSwitchCase="'date-time'">
                            <div class="text-center">
                                <span>{{ col.cell(row).date }}</span>
                                <span class="gap-1">{{ col.cell(row).time }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'nome_com_shipping'">
                            <div class="flex flex-col min-w-30">
                                <span>{{ col.cell(row).name }}</span>
                                <div class="flex gap-1 text-sm">
                                    <span class="capitalize">{{ col.cell(row).shipping_option.toLowerCase() }}</span>
                                    <span>({{ col.cell(row).client_uf }})</span>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'preço'">
                            <div class="flex flex-col">
                                <span class="line-through text-black/60 dark:text-white/80">{{ col.cell(row)[0]}}</span>
                                <span>{{ col.cell(row)[1] }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'pedidoHistory'">
                            <div class="flex flex-col">
                                <span>{{ col.cell(row)[0] }}</span>
                                <span>{{ col.cell(row)[1] }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'review'">
                            <span class="flex flex-nowrap">
                                <mat-icon *ngFor="let item of col.cell(row)"
                                    class="text-base text-black dark:text-white">star</mat-icon>
                            </span>

                            <span *ngIf="col.id === 'role'" class="{{ col.cell(row) }}">{{ col.cell(row) }}</span>
                        </ng-container>

                        <ng-container *ngSwitchCase="'acesso'">
                            <span [class]="col.cell(row)">
                                {{ col.cell(row) }}
                            </span>
                        </ng-container>

                        <ng-container *ngSwitchCase="'pedido'">
                            <span>{{ col.cell(row) }}</span>
                        </ng-container>

                        <ng-container *ngSwitchCase="'pedido-uuid'">
                            <span class="font-semibold">{{ col.cell(row) }}</span>
                        </ng-container>

                        <ng-container *ngSwitchCase="'pedido-status'">
                            <span class="font-bold text-sm pill whitespace-nowrap" [ngClass]="{
                              'text-lime-500': col.cell(row) == 'Aprovado',
                              'text-blue-500':
                                col.cell(row) == 'Preparando Envio'
                                || col.cell(row) == 'Enviado Parcialmente',
                              'text-yellow-500':
                                col.cell(row) == 'Enviado'
                                || col.cell(row) == 'Aguardando Retirada',
                              'text-gray-200': col.cell(row) == 'Cancelado',
                              'text-gray-600': col.cell(row) == 'Completo',
                            }">{{ col.cell(row) }}</span>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <span class="text-xs md:text-base">{{ col.cell(row) }}</span>
                        </ng-container>

                    </ng-container>
                </td>
            </ng-container>

            <!-- Action -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Ações</th>
                <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                    <ng-container *ngIf="!addOption; else addItem">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu-button-options"
                            type="button" *ngIf="!row.hideOptionEdit">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="emitEditEvent(row)" *ngIf="editOption && !row.hideOptionEdit"
                                type="button">
                                <mat-icon class="text-accent dark:text-white">edit</mat-icon>
                                <span class="text-accent dark:text-white">Editar</span>
                            </button>

                            <button mat-menu-item (click)="emitDeleteEvent(row)"
                                *ngIf="deleteOption && !row.hideOptionEdit" type="button">
                                <mat-icon class="text-warn dark:text-yellow-300">delete</mat-icon>
                                <span class="text-warn dark:text-yellow-300">Deletar</span>
                            </button>
                        </mat-menu>
                    </ng-container>

                    <ng-template #addItem>
                        <button mat-icon-button (click)="emitAddEvent(row)" type="button">
                            <mat-icon class="text-primary-700">add_box</mat-icon>
                        </button>
                    </ng-template>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-gray-100 dark:bg-accent"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"
                (click)="(activeEditPage && !use_uuid) ? goToEditPage(row.id) : use_uuid ? goToEditPage(row.uuid) : openModalDetail ? openModal(row) : ''"
                class="dark:bg-accent"
                [ngClass]="{
                    'fade-td': row.status === 0,
                    'hover:cursor-pointer dark:hover:bg-transparent': (activeEditPage || use_uuid || openModalDetail)
                }"
            >
            </tr>
        </table>
    </div>

    <ng-template #emptyTable>
        <div class="empty-table dark:text-white">Nenhum resultado encontrado</div>
    </ng-template>
    <div class="flex justify-between items-center p-2 flex-wrap paginator-wrapper rounded-2xl">
        <div class="total-items">
            <span *ngIf="length">Total de items: <span class="font-bold">{{ length }}</span></span>
        </div>
        <mat-paginator class="flex justify-end dark:text-white" *ngIf="!disablePaginator"
            (page)="handlePageChange($event)" [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="pageSize"
            [pageIndex]="pageIndex" [length]="length" showFirstLastButtons aria-label="Selecione a página desejada">
        </mat-paginator>
    </div>
</ng-template>
